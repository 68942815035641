import { motion } from 'framer-motion';

const HeroBackground = () => {
	return (
		<div>
			<div className='xl:-left-screen fixed top-12 -left-12 aspect-square w-1/2 rounded-full bg-sky-500/20 blur-3xl sm:-left-48 sm:w-2/3 md:-left-1/2 xl:-top-1/2 xl:w-3/4 xl:bg-sky-500/[8%]'></div>
			<div className='fixed top-0 right-0 aspect-square h-full translate-x-3/4 rounded-full bg-sky-500/20 blur-3xl'></div>
			<motion.svg
				id='a'
				className='absolute -top-12 -right-24 z-0 hidden w-2/3 max-w-full md:w-[60%] lg:w-1/2'
				whileInView={{ opacity: 0.02, filter: 'blur(0)' }}
				viewport={{ once: true }}
				initial={{ opacity: 0, filter: 'blur(20px)' }}
				transition={{ duration: 3 }}
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 152.41 219.22'>
				<g fill='var(--sky-500)'>
					<path d='M99.98,141.54c-.65,2.69-1.3,5.38-1.97,8.06-1.68,6.71-4.48,12.8-9.87,17.4-.12,.11-.27,.19-.39,.3-.56,.49-1.15,.38-1.53-.13-.35-.47-.21-.98,.28-1.46,1.3-1.28,2.6-2.56,3.76-3.96,1.97-2.38,3.42-5.09,4.32-8.04,.94-3.09,1.7-6.24,2.54-9.36,.05-.19,.1-.38,.15-.56,1.28-4.37,2.24-12.15,1.91-16.97-.52-7.61-3.26-14.22-9.07-19.34-4.21-3.72-9.19-5.62-14.78-6.03-3.78-.28-7.31,.62-10.76,2.03-4.53,1.85-8.55,4.5-12.13,7.81-3.2,2.96-5.85,6.36-7.92,10.21-1.73,3.22-2.92,6.62-3.75,10.16-.26,1.11-.48,2.24-.56,3.37-.2,2.68-.54,5.38-.41,8.05,.25,5.08,1.06,10.06,3.12,14.79,2.13,4.89,5.26,8.98,9.34,12.38,.4,.34,.85,.68,.58,1.31-.34,.78-.99,.95-1.68,.4-2.5-1.99-4.69-4.27-6.54-6.87-2.74-3.86-4.6-8.11-5.62-12.73-.28-1.24-.57-2.48-.75-3.79-.14-1-.14-1.36-.26-2.59-.38-3.94-.34-7.87,.26-11.77,1.63-10.5,6.44-19.23,14.7-26.03,3.42-2.82,7.15-5.09,11.24-6.77,4.2-1.72,8.54-2.41,13.11-1.79,4.03,.54,7.74,1.85,11.15,4.03,3.35,2.14,6.07,4.93,8.18,8.28,3.16,5.03,4.64,10.57,4.63,16.47,0,2.87-.15,5.8-.49,8.61l-.76,4.54Z' />
					<path d='M92.87,147.31c-.66,2.43-1.36,4.86-2.32,7.17-1.93,4.65-5.09,8.32-9.53,10.78-4.92,2.72-10.16,3.35-15.54,1.92-4.71-1.25-8.48-4.02-11.35-7.99-1.93-2.67-3.23-5.64-4.11-8.8-1.7-6.11-2.01-12.34-1.24-18.58,.75-6.1,2.64-11.86,6.27-16.94,2.75-3.85,6.24-6.8,10.66-8.51,4.12-1.6,8.42-2.01,12.76-1,6.52,1.51,11.32,5.32,14.46,11.21,1.1,2.06,1.77,4.28,2.26,6.57,.9,4.16,.61,8.34,.28,12.52-.04,.46-.1,.98-.33,1.36-.21,.33-.66,.67-1.03,.71-.55,.06-.94-.37-.89-.96,.1-1.13,.36-2.25,.37-3.38,.03-2.75,.26-5.53-.12-8.23-1.02-7.19-4.45-12.93-11.12-16.27-3.57-1.78-7.41-2.36-11.42-1.75-6.91,1.05-11.8,4.94-15.36,10.73-1.98,3.21-3.24,6.7-4.05,10.38-.63,2.85-1.07,5.71-1.03,8.63,.03,2.19,0,4.39,.26,6.55,.58,4.78,1.67,9.44,4.38,13.53,4.42,6.66,10.64,9.73,18.58,8.67,5.11-.68,9.26-3.34,12.39-7.48,2-2.63,3.28-5.61,4.11-8.8,.22-.85,.46-1.69,.68-2.57,.45-1.78,2.3-.6,2,.51Z' />
					<path d='M117.66,125.67s-1.02-2.45-1.43-3.79c-1.06-3.38-2.14-6.75-3.1-10.15-.95-3.35-1.65-6.78-2.65-10.11-1.88-6.28-5.24-11.54-10.91-15.1-2.78-1.75-5.79-2.95-9.06-3.32-2.23-.25-4.48-.45-6.71-.36-4.28,.17-8.16,1.68-11.7,4.07-5.94,4.02-11.35,8.69-16.44,13.72-2.99,2.97-5.88,6.04-8.8,9.08-.51,.53-.94,1.15-1.41,1.72-.55,.65-1.2,.81-1.65,.33-.5-.53-.39-1.09,.05-1.64,2.99-3.75,6.31-7.19,9.81-10.45,2.97-2.76,5.93-5.53,9.01-8.16,3.27-2.8,6.65-5.49,10.41-7.63,3.67-2.08,7.64-3.05,11.84-3.17,4.05-.11,7.97,.52,11.73,2.02,3.67,1.47,6.84,3.69,9.47,6.64,3.31,3.71,5.37,8.05,6.6,12.83,1.12,4.34,2.24,8.68,3.52,12.97,1.02,3.44,2.17,6.85,3.45,10.21,3.04,7.95,7.01,15.38,12.93,21.6,1.95,2.05,4.05,3.97,6.25,5.75,1.01,.81,3.22,2.39,5.19,3.67,.97,.63,1.95,1.25,2.94,1.86,.7,.43,.94,1.02,.6,1.58-.34,.56-.95,.63-1.67,.18-3.59-2.25-5.96-3.94-6.82-4.61l-3.14-2.39c-4.79-3.96-8.67-8.7-11.94-13.97-2.29-3.7-4.1-7.63-5.76-11.64-.21-.51-.36-1.03-.61-1.74Z' />
					<path d='M18.73,110.33c1.15-3.84,2.15-7.74,3.5-11.51,2.35-6.56,5.5-12.72,9.66-18.35,4.27-5.79,9.1-11.06,14.3-16,4.71-4.47,9.64-8.68,14.91-12.47,4.32-3.1,8.99-5.54,14.04-7.2,7.42-2.44,14.99-2.89,22.69-1.69,3.94,.61,7.76,1.66,11.42,3.17,5.83,2.41,11.04,5.82,15.63,10.15,1.55,1.46,2.95,3.06,4.4,4.62,.85,.91-.33,2.46-1.37,1.52-.3-.27-.54-.62-.81-.92-4.51-5.11-9.79-9.22-15.91-12.23-3.51-1.73-7.21-2.94-11.04-3.8-3.33-.75-6.7-1.08-10.08-1.1-3.35-.02-6.69,.31-9.99,1.06-7.44,1.68-13.99,5.11-19.98,9.71-7.74,5.95-14.9,12.51-21.33,19.87-3.35,3.83-6.49,7.82-8.99,12.26-1.76,3.12-3.29,6.39-4.66,9.71-1.32,3.22-2.33,6.56-3.4,9.88-.42,1.29-.66,2.64-1.03,3.95-.49,1.76-2.48,1.05-1.99-.59Z' />
					<path d='M4.04,138.52c.22-3.96,.3-7.94,.7-11.89,.5-4.85,1.15-9.7,2.3-14.46,.59-2.42,1-4.89,1.69-7.28,.94-3.27,1.97-6.52,3.12-9.72,.94-2.62,2.05-5.19,3.18-7.74,1.85-4.18,4.18-8.09,6.78-11.84,3.44-4.95,7.34-9.52,11.5-13.87,4.23-4.43,8.67-8.64,13.44-12.49,3.33-2.68,6.74-5.26,10.23-7.73,3.29-2.33,6.85-4.25,10.57-5.82,2.8-1.19,5.66-2.22,8.5-3.31,.25-.1,.53-.15,.8-.18,.63-.07,1.01,.29,1.13,.85,.13,.59-.25,.93-.76,1.12-1.88,.67-3.8,1.23-5.63,1.99-2.35,.98-4.66,2.04-6.93,3.18-6.93,3.51-12.97,8.28-18.82,13.32-5.72,4.93-11.08,10.22-16,15.94-4.45,5.17-8.42,10.67-11.54,16.75-2.06,4.01-3.87,8.15-5.21,12.46-1.17,3.78-2.4,7.56-3.31,11.41-.95,4.01-1.67,8.09-2.3,12.17-1.1,7.07-1.5,14.19-1.24,21.34,.08,2.33,.34,4.65,.48,6.98,.02,.41-.06,.9-.29,1.22-.15,.21-.77,.36-.94,.23-.35-.28-.72-.74-.77-1.16-.19-1.76-.32-3.54-.37-5.32-.06-2.05-.01-4.11-.01-6.16h-.3Z' />
					<path d='M26.36,131.77c.62-3.46,1.04-6.97,1.88-10.38,2.29-9.28,6.57-17.57,13.06-24.62,2.94-3.19,6.01-6.27,9.15-9.27,4.29-4.1,8.94-7.78,13.83-11.14,3.69-2.53,7.68-4.51,12.06-5.58,5.13-1.25,10.32-1.42,15.55-.67,4.12,.59,8.06,1.78,11.76,3.67,5.41,2.76,9.87,6.65,13.4,11.61,2.67,3.75,4.54,7.89,5.92,12.26,.2,.63,.36,1.28,.5,1.93,.14,.64-.15,1.11-.78,1.26-.6,.14-1.01-.12-1.18-.74-.92-3.4-2.11-6.69-3.82-9.78-2.38-4.32-5.45-8.05-9.35-11.08-3.67-2.85-7.73-4.88-12.22-6.13-4.78-1.32-9.61-1.7-14.52-1.19-5.21,.55-10.02,2.27-14.45,5.07-6.33,4-12.01,8.82-17.43,13.94-4.15,3.93-8.02,8.12-11.34,12.78-2.17,3.05-3.89,6.33-5.35,9.77-1.44,3.4-2.56,6.92-3.28,10.52-.62,3.09-.86,6.26-1.32,9.39-.07,.45-.28,1.02-.61,1.25-.66,.45-1.42-.13-1.41-1.03,.02-2.02,.36-1.79-.06-1.86Z' />
					<path d='M125.49,74.44c2.01,3.15,3.62,6.52,4.96,9.99,.81,2.09,1.43,4.25,2.06,6.4,1.25,4.28,2.38,8.59,3.69,12.85,1.25,4.07,2.67,8.08,4.81,11.8,2.32,4.03,5.41,7.3,9.36,9.77,.19,.12,.36,.26,.56,.35,.69,.33,.9,.92,.55,1.51-.36,.62-1.02,.62-1.6,.2-1.55-1.1-3.19-2.11-4.63-3.35-3.42-2.96-5.84-6.66-7.72-10.76-1.96-4.29-3.3-8.79-4.54-13.32-.96-3.49-1.96-6.98-3.01-10.45-1.64-5.4-3.94-10.5-7.05-15.22-3.29-5-7.41-9.18-12.4-12.5-3.65-2.42-7.61-4.12-11.88-5.14-5.27-1.26-10.58-1.44-15.85-.26-2.41,.54-4.76,1.48-7.03,2.49-2.39,1.07-4.74,2.28-6.92,3.71-3.13,2.06-6.13,4.32-9.1,6.61-2,1.54-3.85,3.28-5.78,4.93-.5,.43-1.06,.55-1.56,.02-.46-.49-.38-1.03,.11-1.46,2-1.74,3.92-3.57,6.03-5.17,3.41-2.58,6.87-5.1,10.44-7.44,2.86-1.88,6.02-3.24,9.25-4.43,3.99-1.47,8.07-2.18,12.3-2.08,4.07,.09,8.04,.75,11.92,2.04,7.06,2.36,12.91,6.47,17.84,11.97,.9,1.01,1.73,2.08,2.57,3.13,0,0,1.91,2.7,2.61,3.79Z' />
					<path d='M47.69,183.16c-6.06-4.09-10.92-9.3-14.46-15.69-1.62-2.93-2.97-6-3.89-9.23l-.82-2.53c-1.22-4.69-1.89-9.45-2.15-14.28-.03-.5-.12-.99-.13-1.49-.01-.86,.41-1.41,1.05-1.43,.67-.02,1.04,.48,1.09,1.38,.07,1.53,.09,3.06,.26,4.57,.23,2.09,.58,4.17,.88,6.26,.04,.3,.09,.6,.13,.89,.09,.63,.08,.53,.34,1.46,.35,1.28,.64,2.58,1.03,3.84,2.79,9.09,7.64,16.83,15.25,22.64,2.16,1.65,4.62,2.9,6.98,4.26,2.93,1.69,6.16,2.65,9.44,3.32,4.82,.99,9.71,1.14,14.57,.27,3.49-.63,6.94-1.51,10.45-2.02,4.06-.59,8.16-.94,12.25-1.32,5.02-.46,10.03-.2,15.02,.39,4.08,.48,8.12,1.24,11.98,2.71,1.86,.71,3.72,1.43,5.56,2.18,.95,.38,1.25,1.36,.54,1.78-.39,.23-1.08,.16-1.55,0-2.29-.82-4.52-1.83-6.83-2.57-5.03-1.59-10.21-2.43-15.48-2.5-4.43-.06-8.87,.04-13.29,.32-3.07,.19-6.12,.74-9.15,1.26-3.47,.6-6.89,1.56-10.37,1.96-3.1,.36-6.26,.27-9.39,.17-3.57-.11-7.02-1.03-10.36-2.25-.18-.07-5.34-1.93-8.96-4.37Z' />
					<path d='M123.24,65.56c-.19-.2-.37-.39-.54-.58-3.7-4.13-7.99-7.54-12.91-10.1-4.08-2.12-8.39-3.53-12.95-4.28-3.76-.62-7.52-.84-11.28-.34-2.68,.36-5.36,.95-7.94,1.74-6.48,1.98-11.92,5.78-17.13,10.01-4.83,3.92-9.33,8.2-13.73,12.59-3.24,3.23-6,6.86-8.56,10.63-2.47,3.63-4.76,7.37-7.13,11.06-.38,.59-.99,.81-1.47,.46-.57-.41-.61-.95-.25-1.53,1.12-1.84,2.15-3.73,3.37-5.5,2.73-3.95,5.49-7.87,8.35-11.73,2.7-3.64,6.07-6.68,9.33-9.81,4.61-4.42,9.44-8.56,14.69-12.21,3.19-2.22,6.61-4,10.26-5.36,4.12-1.54,8.35-2.4,12.73-2.58,4.67-.19,9.24,.34,13.75,1.56,7.39,1.99,13.81,5.69,19.34,10.95,1.27,1.21,2.48,2.49,3.69,3.77,1.04,1.1-.51,2.42-1.62,1.25Z' />
					<path d='M34.81,149.19c.17,1.3-1.55,1.73-1.86,.29-.04-.16-2.05-7.98-.68-19.52,.54-4.54,1.65-8.98,3.25-13.29,1.96-5.26,4.71-10.04,8.28-14.35,3.66-4.42,7.69-8.49,12.02-12.26,4.16-3.62,8.41-7.14,13.09-10.11,5.49-3.48,11.5-4.98,17.96-4.71,2.07,.09,4.14,.4,6.2,.68,.92,.12,1.28,.65,1.11,1.32-.16,.62-.67,.83-1.57,.65-5.64-1.13-11.23-.94-16.69,.91-3.31,1.12-6.3,2.94-9.11,5.02-4.34,3.22-8.54,6.63-12.47,10.36-3.94,3.74-7.72,7.64-10.94,12.04-2.18,2.98-3.91,6.19-5.34,9.59-1.5,3.57-2.55,7.27-3.26,11.05-.49,2.58-.73,5.23-.8,7.86-.09,3.21,.03,6.43,.17,9.65,.04,.96,.28,2.12,.64,4.82Z' />
					<path d='M43.86,44.67c.37-.39,.66-.84,1.06-1.09,1.4-.89,2.52-2.11,3.84-3.09,4.06-3.03,8.25-5.85,12.85-7.98,2.7-1.26,5.51-2.3,8.3-3.35,3.99-1.51,8.17-2.3,12.37-2.83,4.16-.53,8.38-.65,12.56-.19,3.58,.39,7.18,.84,10.68,1.66,3.35,.78,6.63,1.96,9.87,3.15,3.67,1.34,7.08,3.24,10.38,5.32,3.67,2.32,7.11,4.96,10.29,7.92,.53,.49,1.05,.98,1.57,1.49,.67,.67,.78,1.35,.31,1.8-.44,.42-1.11,.33-1.75-.32-2.63-2.66-5.52-5.01-8.56-7.17-3.84-2.72-7.92-5.01-12.26-6.86-3.25-1.39-6.58-2.51-9.99-3.33-3.89-.93-7.84-1.52-11.84-1.74-4.34-.24-8.65-.05-12.94,.6-4.4,.66-8.66,1.83-12.82,3.41-6.35,2.41-12.15,5.8-17.52,9.94-2.66,2.06-5.23,4.23-7.84,6.35-.61,.5-1.38,.59-1.72,.21-.44-.49-.28-1.21,.41-1.8,.63-.54,1.26-1.07,1.91-1.58,.28-.21,.61-.35,.85-.49Z' />
					<path d='M90.68,4.06c2.11,0,4.21-.02,6.32,0,.91,.01,1.26,.39,1.19,1.11-.06,.62-.55,.89-1.38,.86-4.84-.2-9.63-.04-14.4,1.02-6.61,1.47-12.71,4.11-18.54,7.47-6.03,3.48-11.55,7.65-16.8,12.19-6.83,5.91-12.96,12.49-18.99,19.2-4.15,4.61-8.63,8.93-12.97,13.38-.81,.83-1.66,1.63-2.49,2.45-.51,.5-1.05,.76-1.66,.2-.51-.47-.41-1.02,.27-1.7,2.8-2.76,5.67-5.46,8.39-8.31,3.6-3.77,7.08-7.65,10.6-11.5,5.25-5.73,10.72-11.22,16.65-16.25,5.15-4.37,10.57-8.36,16.44-11.71,4.21-2.4,8.59-4.43,13.21-5.94,4.62-1.51,9.33-2.41,14.19-2.48Z' />
					<path d='M35.66,153.08c.46,1.48,.89,2.95,1.39,4.39,1.64,4.68,4.04,8.94,7.28,12.71,3.56,4.15,7.87,7.31,12.89,9.47,5,2.16,10.26,3.1,15.68,2.64,2.86-.24,5.7-.83,8.53-1.33,7.01-1.24,14.04-2.17,21.18-2.24,4.51-.04,9,.03,13.45,.8,1.69,.29,3.36,.72,5.03,1.12,.56,.13,.94,.53,.82,1.14-.13,.65-.72,.94-1.44,.75-8.46-2.21-17.05-2.14-25.65-1.34-4.19,.39-8.36,1.07-12.54,1.68-3.1,.46-6.16,1.17-9.27,1.49-3.64,.38-7.26-.08-10.82-.93-4.86-1.16-9.27-3.26-13.28-6.21-3.47-2.55-6.4-5.64-8.81-9.21-2.8-4.13-4.82-8.61-6.08-13.44-.09-.35-.21-.7-.3-1.06-.25-1.03,1.7-1.26,1.96-.43Z' />
					<path d='M135.39,159.77l-4.64-3.9c-3.74-3.43-7.02-7.27-9.85-11.49-2.22-3.31-4.14-6.79-5.78-10.43-.37-.83-.71-1.68-1.06-2.52-1.73-4.06-2.5-5.65-5.12-14.44-1.18-3.97-2.33-7.96-3.32-11.99-1-4.1-2.6-7.86-5.54-10.95-2.75-2.88-6.13-4.64-10.05-5.39-2.97-.56-5.93-.59-8.84,.12-1.48,.36-2.86,1.15-4.3,1.7-.46,.18-1.03,.37-1.46,.25-.82-.24-.84-1.24,.01-1.74,1.14-.66,2.33-1.31,3.57-1.72,4.69-1.54,9.44-1.41,14.1,.03,4.71,1.46,8.39,4.39,11.04,8.57,1.78,2.81,2.81,5.91,3.59,9.13,.81,3.33,1.8,6.62,2.75,9.91,.67,2.34,1.39,4.66,2.13,6.98,.52,1.63,1.14,3.23,1.68,4.85,1.24,2.88,1.65,4.21,3.26,7.57,3.65,7.67,8.54,14.43,14.75,20.23,.45,.42,.89,.83,1.38,1.2,0,0,1.57,1.27,2.8,2.32,1.43,1.24,.04,2.56-1.07,1.7Z' />
					<path d='M59.32,137.82c.1-4.6,.93-9.01,2.84-13.18,1.33-2.91,3.15-5.45,5.94-7.14,4.84-2.94,11.45-1.79,14.53,3.36,1.37,2.28,2.03,4.79,2.06,7.43,.02,2.02,.05,4.08-.25,6.07-.38,2.51-1.07,4.97-1.65,7.45-.36,1.56-.71,3.12-1.14,4.67-.81,2.9-1.82,5.73-4.13,7.81-4.44,4-11.39,3.49-14.9-2.28-1.41-2.31-2.18-4.84-2.56-7.49-.32-2.22-.5-4.46-.74-6.69Zm2.18,1.53c.11,1.01,.28,2.64,.48,4.26,.33,2.75,1.08,5.35,2.64,7.69,2.82,4.23,8.03,4.56,11.44,1.52,1.84-1.63,2.77-3.8,3.39-6.06,1-3.65,1.87-7.34,2.65-11.04,.72-3.44,.94-6.91,.13-10.39-.47-2.02-1.35-3.78-2.86-5.22-3.35-3.18-8.98-2.78-12.36,.86-1.94,2.08-3.18,4.52-4.06,7.21-1.12,3.44-1.5,6.97-1.46,11.18Z' />
					<path d='M80.9,112.05c.49,.25,.99,.52,1.47,.82,3.42,2.23,5.65,5.37,6.84,9.26,1.35,4.43,1.31,8.94,.56,13.44-.47,2.86-1.09,5.71-1.81,8.52-.68,2.7-1.38,5.43-2.42,8-1.75,4.34-4.72,7.58-9.24,9.25-4.22,1.56-8.35,1.37-12.32-.67-2.74-1.4-4.82-3.57-6.35-6.25-1.71-3-2.66-6.23-3.17-9.64-.6-3.99-.56-7.97-.27-11.97,0-.11,.01-.22,.02-.33,.1-.98,.59-1.53,1.26-1.45,.66,.08,.79,.5,.78,1.58-.04,3.44-.24,6.9,.02,10.32,.29,3.85,1.31,7.57,3.39,10.91,3.02,4.85,8.32,7.42,13.92,6.19,4.82-1.05,8.12-4.04,9.95-8.52,.99-2.41,1.58-4.99,2.26-7.53,.67-2.51,1.28-5.04,1.81-7.58,.7-3.39,.87-6.84,.4-10.27-.73-5.35-3.09-9.69-8.06-12.28-1.37-.72-.57-2.6,.96-1.82Z' />
					<path d='M43.07,139.76c.33-2.94,.72-6.58,1.15-10.21,.1-.82,.32-1.63,.47-2.45,.13-.71,.66-1.2,1.22-1.11,.65,.1,.92,.55,.8,1.4-.37,2.55-.77,5.1-1.12,7.65-.56,4.07-.47,8.17,.17,12.21,1.27,8.07,4.84,14.91,11.48,19.87,3.73,2.79,8.05,4.05,12.73,4.25,3.3,.14,6.44-.68,9.62-1.27,4.81-.89,9.63-1.62,14.52-1.86,3.16-.15,6.32-.29,9.49-.41,.75-.03,1.5,.03,2.25,.07,.66,.03,1.13,.33,1.17,1.03,.03,.59-.53,1-1.28,1.01-2.61,.02-5.23-.08-7.82,.1-4.15,.29-8.31,.65-12.43,1.2-3.6,.49-7.14,1.37-10.73,1.93-3.55,.56-7.09,.34-10.57-.58-7.62-2-12.87-6.93-16.65-13.61-2.43-4.28-3.65-8.94-4.05-13.83-.13-1.55-.24-3.1-.41-5.38Z' />
					<path d='M108.72,132.92c-.44-1.1-2.1-4.74-2.61-6.24-1.23-3.58-2.23-7.24-3.58-10.77-1.67-4.37-4.21-8.23-7.6-11.51-3.35-3.25-7.25-5.6-11.69-7.04-4.4-1.42-8.9-1.8-13.49-1.21-.5,.06-1,.09-1.49,.14-.6,.06-1.07-.12-1.17-.78-.1-.68,.3-1.06,.92-1.16,1.1-.18,2.2-.32,3.3-.44,3.21-.34,6.36,.07,9.5,.71,3.96,.81,7.59,2.37,10.95,4.59,4.36,2.89,7.76,6.67,10.38,11.19,1.83,3.16,2.96,6.57,4.04,10.03,.98,3.14,2.19,6.2,3.26,9.31,.42,1.22,1.9,4.49,2.07,4.89,1.82,4.22,3.59,7.11,5.69,10.48,3.3,5.3,7.44,9.94,12.06,14.14,.24,.22,.47,.44,.72,.67,.84,.79,.06,2.23-1.08,1.73-9.13-7.92-15.72-17.56-20.19-28.75Z' />
					<path d='M96.07,38.19c5.41,.13,10.67,1,15.66,3.11,7.86,3.33,14.61,8.22,20.35,14.53,.53,.58,1.08,1.13,1.57,1.74,1.77,2.17,4.41,5.83,4.14,5.46,2.96,4.26,5.39,8.8,7.36,13.6,1.87,4.57,3.27,9.29,4.5,14.07,.48,1.85,1.05,3.67,1.6,5.5,.2,.66,.21,1.24-.5,1.53-.67,.28-1.2-.06-1.45-.93-.78-2.74-1.46-5.52-2.31-8.24-1.11-3.57-2.22-7.14-3.54-10.63-2.05-5.4-4.87-10.41-8.28-15.07-.42-.58-.85-1.15-1.29-1.72-5.14-6.56-11.15-11.87-18.45-15.82-3.28-1.77-6.7-3.33-10.35-3.99-3.56-.65-7.22-.77-10.84-1.11-.41-.04-.83,0-1.25-.01-.63-.03-.98-.36-.99-1,0-.64,.36-.99,.97-1.01,1.03-.03,2.06,0,3.08,0Z' />
					<path d='M70.81,195.53c-5.03-.02-9.97-.75-14.74-2.33-6.77-2.25-12.8-5.8-18.04-10.69-4.47-4.18-8.05-9.01-10.74-14.48-1.33-2.7-2.27-5.59-3.37-8.4-.4-1.03,1.65-1.55,1.96-.55,2.78,8.97,7.97,17.33,15.53,23.67,2.89,2.43,6.09,4.36,9.44,6.08,4.6,2.35,9.5,3.71,14.58,4.35,5.07,.64,10.13,.35,15.14-.77,3.03-.68,6.1-1.14,9.16-1.67,1.31-.23,2.64-.37,3.96-.54,.69-.09,1.23,.1,1.35,.88,.1,.64-.39,1.1-1.21,1.17-4.71,.4-9.31,1.46-13.93,2.38-2.33,.46-4.73,.56-7.1,.8-.66,.07-1.33,.07-2,.1Z' />
					<path d='M80.61,1.09c.02,.66-.58,.87-1.14,1-5.89,1.38-11.38,3.8-16.65,6.68-5.02,2.75-9.73,5.99-14.23,9.54-5.54,4.36-10.75,9.08-15.62,14.16-3.85,4.03-7.54,8.21-11.32,12.31-.97,1.05-1.97,2.07-2.97,3.1-.72,.75-1.23,.84-1.77,.33-.48-.45-.41-1,.26-1.73,3.39-3.7,6.79-7.4,10.18-11.1,5.41-5.9,11.13-11.47,17.3-16.56,5.61-4.63,11.54-8.81,17.97-12.26,3.86-2.07,7.86-3.82,11.99-5.25,1.46-.51,2.98-.87,4.48-1.25,.91-.23,1.51,.17,1.51,1.02Z' />
					<path d='M0,119.76c.29-1.68,.57-3.64,.99-5.56,.86-3.98,1.63-7.99,2.76-11.9,1.22-4.22,2.72-8.36,4.24-12.49,1.46-3.97,3.36-7.75,5.49-11.41,2.47-4.26,5.32-8.25,8.4-12.09,4.12-5.15,8.73-9.83,13.47-14.4,.91-.88,1.54-.84,1.98,.07,.28,.59-.09,.95-.43,1.3-2.63,2.66-5.34,5.24-7.89,7.98-5.07,5.45-9.68,11.25-13.47,17.69-2.96,5.03-5.34,10.32-7.28,15.8-1.25,3.54-2.32,7.17-3.23,10.82-1.07,4.3-1.89,8.66-2.8,13-.12,.57-.11,1.16-.21,1.73-.11,.66-.48,1.06-1.21,.96C.06,121.16,0,120.61,0,119.76Z' />
					<path d='M150.78,141.19c-1.86-1.64-3.79-3.21-5.54-4.96-2.72-2.74-4.95-5.88-6.67-9.34-1.2-2.42-2.31-4.9-3.34-7.4-.8-1.94-1.49-3.93-2.08-5.95-.42-1.43-1.04-2.77-1.37-4.25-.74-3.32-1.73-6.58-2.66-9.85-.69-2.42-1.31-4.87-2.19-7.22-1.74-4.63-4.13-8.93-7.36-12.69-2.84-3.32-3.73-4.17-5.9-5.84-.31-.24-.62-.47-.92-.71-5-3.88-10.64-6.39-16.79-7.75-3.68-.81-7.41-1.08-11.17-1.04-5.37,.04-10.53,1.07-15.47,3.14-3.97,1.67-7.6,3.96-11.03,6.54-4.96,3.73-9.74,7.68-14.19,12.01-4.88,4.75-9.47,9.73-13.2,15.44-.86,1.31-1.61,2.69-2.04,3.41-.49,.82,1.03,2.29,1.63,1.17,1.26-2.35,1.73-3.55,3.68-5.77,2.77-3.18,5.32-6.57,8.23-9.61,6.54-6.81,13.7-12.92,21.64-18.05,3.7-2.39,7.7-4.17,11.99-5.16,5.07-1.18,10.21-1.39,15.4-.76,4.19,.51,8.22,1.58,12.06,3.29,4.71,2.1,8.89,5.01,12.45,8.77l.84,.92c1.38,1.76,2.82,3.47,4.08,5.3,2.01,2.93,3.57,6.12,4.66,9.5,1.03,3.18,1.76,6.46,2.69,9.67,1.08,3.75,2.14,7.51,3.37,11.2,1.03,3.09,2.23,6.13,3.49,9.14,1.87,4.46,4.38,8.54,7.61,12.16,2.1,2.35,4.36,4.52,6.93,6.36,.49,.35,.92,.51,1.41,.04,.52-.5,.42-1.13-.22-1.7Z' />
					<path d='M92.74,9.25c4.76,.37,9.52,.72,14.27,1.13,1.16,.1,2.3,.35,3.45,.55,.87,.16,1.23,.58,1.11,1.26-.11,.62-.65,.8-1.53,.71-4.55-.47-9.11-.98-13.67-1.31-2.4-.17-4.83-.05-7.24,.05-3.04,.12-5.99,.79-8.9,1.64-5.8,1.7-11.11,4.43-16.22,7.61-4.51,2.81-8.7,6.05-12.77,9.46-.52,.44-1.04,.56-1.55,.12-.46-.39-.33-1.2,.24-1.69,4.63-3.91,9.4-7.63,14.69-10.62,2.98-1.69,6.01-3.3,9.14-4.68,5.18-2.28,10.6-3.74,16.31-3.95,.89-.03,1.77-.09,2.66-.13l.02-.16Z' />
					<path d='M66.28,197.87c1.51,.21,3.01,.48,4.53,.6,3.25,.27,6.48,.06,9.69-.54,6.98-1.3,13.97-2.54,21.1-2.75,4.84-.14,9.65,.04,14.44,.81,3.32,.53,6.54,1.44,9.64,2.75,.81,.34,1.14,.83,.9,1.41-.26,.64-.88,.78-1.7,.49-1.98-.68-3.96-1.43-5.98-1.96-3.85-1.01-7.8-1.43-11.78-1.48-4.7-.06-9.39,0-14.05,.67-3.4,.48-6.78,1.13-10.17,1.74-5.19,.93-10.39,1.47-15.63,.45-.43-.08-.87-.17-1.3-.3-.56-.17-.93-.54-.82-1.16,.1-.61,.53-.88,1.13-.75Z' />
					<path d='M128.76,69.37c1.59,2.75,3.28,5.44,4.74,8.25,1.98,3.82,3.24,7.93,4.46,12.04,1.11,3.74,2.04,7.54,3.18,11.28,1.28,4.21,2.64,8.4,4.91,12.21,1.5,2.53,3.42,4.68,5.77,6.44,.39,.3,.76,.55,.52,1.05-.26,.55-.79,.83-1.38,.65-.43-.13-.84-.41-1.2-.7-3.17-2.58-5.46-5.83-7.12-9.53-1.99-4.43-3.34-9.08-4.65-13.75-1.25-4.48-2.48-8.97-4.03-13.35-1.73-4.88-4.34-9.29-7.04-13.69-.95-1.54,1.05-2.27,1.85-.9Z' />
					<path d='M80.22,211.13c-1.79,.12-7.26,.07-8.71,0-3.56-.18-7.04-.91-10.46-1.88-3.55-1.01-6.99-2.35-10.18-4.2-2.76-1.61-5.38-3.47-8.06-5.21-.09-.06-.18-.12-.27-.19-.51-.43-.74-.97-.29-1.52,.44-.54,1-.47,1.56-.08,1.93,1.34,3.82,2.74,5.82,3.97,2.9,1.79,5.94,3.34,9.21,4.32,2.94,.88,5.91,1.74,8.92,2.29,1.69,.31,7.2,.8,9.97,.63,3.92-.24,7.76-.99,11.61-1.68,2.67-.48,5.39-.74,8.09-1.06,.62-.07,1.18,.13,1.28,.88,.08,.64-.35,1.09-1.12,1.15-4.66,.39-9.24,1.21-13.81,2.16-1.14,.23-2.31,.36-3.56,.45Z' />
					<path d='M69.09,179.05c-4.64-.37-8.96-1.67-13.03-3.82-.71-.37-.93-.94-.63-1.46,.34-.59,.93-.71,1.64-.33,3.85,2.04,7.93,3.22,12.3,3.42,3.19,.15,6.29-.31,9.4-.97,3.36-.71,6.77-1.21,10.17-1.73,6.2-.95,12.44-1.1,18.7-1.02,1.44,.02,2.89,.1,4.33,.17,.27,.01,.63,0,.8,.16,.31,.29,.73,.74,.68,1.06-.05,.32-.59,.75-.96,.8-.73,.1-1.49-.02-2.24-.06-5.56-.3-11.1-.27-16.63,.44-4.04,.52-8.09,1.04-12.12,1.65-1.69,.26-3.34,.86-5.04,1.07-2.44,.32-4.91,.43-7.36,.63Z' />
					<path d='M99.24,77.87c.77,.33,1.48,.58,2.13,.93,5.6,2.98,9.97,7.24,13.07,12.77,1.72,3.07,2.89,6.37,3.78,9.78,1.17,4.52,2.45,9.02,3.77,13.5,.75,2.54,1.69,5.02,2.51,7.54,.12,.38,.16,.86,.03,1.21-.1,.26-.57,.59-.81,.54-.37-.07-.87-.36-1-.68-.75-1.93-1.46-3.88-2.09-5.85-.81-2.53-1.56-5.07-2.27-7.63-.65-2.32-1.28-4.64-1.83-6.98-1.5-6.44-4.14-12.27-8.86-17.03-2.43-2.45-5.21-4.42-8.36-5.85-1.02-.46-1.31-1.05-.84-1.69,.18-.24,.5-.38,.76-.57Z' />
					<path d='M12.69,174.56c1.78,4.5,4.03,8.77,6.68,12.83,3.32,5.11,7.16,9.78,11.55,14,.3,.29,.56,.56,.77,.81,.41,.48-.79,2.05-1.64,1.22-.23-.22-.45-.44-.67-.68-.07-.07-1.45-1.41-1.47-1.42-3.65-3.71-6.9-7.73-9.78-12.06-2.19-3.3-4.14-6.74-5.77-10.34-.95-2.09-1.78-4.23-2.66-6.35-.07-.17-1.51-4.14-1.99-6.25-.65-2.86-1.31-5.72-1.95-8.59-.07-.32-.1-.66-.11-.99-.02-.57,.22-.97,.84-1.03,.61-.06,1.02,.16,1.16,.8,.68,3.08,1.34,6.17,2.04,9.25,.19,.83,.29,1.67,.59,2.57,.66,2.02,.75,2,2.42,6.22Z' />
					<path d='M86.59,204.02c-.39,.25-.74,.63-1.16,.72-4.26,.87-8.57,1.3-12.92,.97-6.14-.47-12-1.99-17.54-4.74-5.88-2.92-10.97-6.86-15.53-11.55-.23-.24-.48-.45-.68-.71-.39-.5-.59-1.06-.09-1.58,.51-.53,1.03-.36,1.53,.11,1.8,1.71,3.56,3.46,5.42,5.09,4.07,3.56,8.64,6.34,13.66,8.33,4.51,1.79,9.22,2.77,14.08,3.03,3.65,.19,7.26-.1,10.84-.82,.52-.1,1.02-.22,1.34,.46,.11,.23,.59,.29,.9,.43l.15,.26Z' />
					<path d='M133.22,66.15c1.49,2.42,3.13,4.78,4.36,7.33,1.63,3.37,3.05,6.87,4.35,10.38,.93,2.51,1.51,5.15,2.28,7.73,1.24,4.13,2.49,8.26,3.78,12.38,.82,2.62,1.9,5.12,3.49,7.4,.36,.52,.47,1.16-.17,1.63-.53,.39-1.16,.25-1.58-.53-1.1-2.07-2.3-4.12-3.11-6.3-1.29-3.44-2.34-6.97-3.4-10.49-.95-3.15-1.7-6.37-2.7-9.5-.92-2.86-1.97-5.7-3.15-8.46-1.62-3.8-3.83-7.26-6.05-10.75-.82-1.28,1.2-1.97,1.91-.81Z' />
					<path d='M22.77,155.53c-.33-1.38-.56-2.7-.81-4.01-.67-3.52-.92-7.09-1.12-10.67-.26-4.63,.09-9.2,.7-13.78,.6-4.46,1.74-8.8,3.16-13.06,.44-1.31,.97-2.59,1.52-3.85,.01-.03,.81-1.61,.83-1.62,.18-.36,.33-.66,.45-.92,.7-1.52,2.39-.67,1.79,.76-1.09,2.6-2.22,5.19-3.14,7.85-1.46,4.26-2.41,8.65-2.89,13.12-.49,4.61-.74,9.25-.23,13.88,.33,3.03,.73,6.06,1.13,9.08,.12,.9,.3,1.81,.56,2.68,.27,.91-1.71,1.55-1.96,.53Z' />
					<path d='M119.39,172.99c2.79,2.21,5.43,4.48,8.26,6.49,2.47,1.75,5.13,3.24,7.73,4.8,1.01,.6,1.39,1.06,1.05,1.68-.33,.61-1.01,.65-1.99,.15-5.65-2.89-10.67-6.66-15.39-10.86-1.59-1.41-5.19-4.9-5.35-5.06-4.91-4.87-9.2-10.25-12.92-16.07-.15-.23-.23-.51-.39-.73-.43-.6-.3-1.11,.24-1.52,.45-.34,1.11-.12,1.49,.45,.99,1.5,1.95,3.01,2.97,4.48,2.97,4.3,6.37,8.23,10.07,11.93,.64,.64,3.04,3.02,4.24,4.28Z' />
					<path d='M17.4,159.5c-3.14-14.7-2.72-29.32,.19-43.93,.07-.36,.11-.62,.17-.83,.56-1.94,2.1-.42,1.95,.44-1.12,6.09-1.99,12.2-2.33,18.39-.32,5.82-.07,11.59,.69,17.35,.35,2.69,.89,5.35,1.35,8.02,.26,1.48-1.71,2-2.02,.55Z' />
					<path d='M122.68,24.24c-.62-.27-1.1-.49-1.59-.67-3.48-1.28-6.89-2.78-10.45-3.76-3.7-1.03-7.52-1.69-11.32-2.29-5.65-.88-11.34-.77-17.03-.29-.77,.07-1.54,.22-2.31,.31-.65,.07-1.19-.12-1.29-.85-.09-.74,.37-1.03,1.05-1.09,2.46-.23,4.91-.61,7.37-.66,3.27-.06,6.56-.05,9.8,.3,3.8,.4,7.59,1.02,11.31,1.88,3.44,.8,6.79,1.99,10.15,3.11,1.54,.51,3.02,1.22,4.48,1.95,.34,.17,.63,.77,.62,1.17,0,.3-.52,.6-.8,.9Z' />
					<path d='M138.35,54.62c-.09-.12-.18-.28-.29-.4-3.9-4.53-8.06-8.78-12.94-12.28-2.64-1.89-5.42-3.53-8.44-4.73-3.18-1.26-6.47-2.15-9.86-2.6-2.8-.37-5.62-.73-8.44-.82-2.54-.08-5.1,.15-7.65,.37-4.72,.39-9.31,1.52-13.72,3.2-3.3,1.25-6.53,2.74-9.68,4.33-4.49,2.28-8.68,5.09-12.65,8.19-6.74,5.27-13.08,10.98-18.9,17.27-3.87,4.18-7.47,8.57-10.61,13.32-3.86,5.82-6.78,12.12-8.91,18.74-2.88,8.96-4.94,19.68-4.99,19.92-.27,1.23,0,0-.55,3.95-.31,2.23-.54,4.47-.64,6.71-.18,4.08-.43,8.16-.32,12.23,.15,5.66,.68,11.3,1.89,16.85,.29,1.35,.58,2.71,.99,4.03,.22,1.25,2.09,.77,1.77-.54-.13-.55-.4-1.42-.86-4.36-.47-3.07-1.1-6.13-1.31-9.22-.31-4.34-.42-8.7-.4-13.06,.02-4.8,.43-9.59,1.21-14.35,.83-5.03,.64-3.72,.91-5.35,.02-.14,.09-.32,.13-.48,1.14-4.54,2.13-9.12,3.48-13.59,1.09-3.59,2.54-7.08,4-10.54,2.35-5.58,5.62-10.62,9.35-15.38,3.6-4.6,7.55-8.86,11.73-12.94,4.31-4.21,8.86-8.16,13.63-11.83,5.36-4.12,11.06-7.73,17.32-10.34,2.93-1.22,5.94-2.33,9-3.14,3.07-.8,6.25-1.24,9.4-1.7,4.2-.62,8.44-.36,12.62,.16,2.79,.35,5.52,1.18,8.26,1.86,3.73,.93,7.04,2.75,10.19,4.9,4.32,2.95,8.09,6.51,11.55,10.41,.75,.84,1.5,1.67,2.36,2.63,.72,.8,.62,.68,1.2,1.41,0,0,.83,1.25,1.81,1.83,.1,.06,.24-.15,.35-.1,.57,.28,1.15,.37,1.61,.02-.23-.32-3.35-4.3-3.57-4.6Z' />
					<path d='M60.05,3.7c-.22,.21-.54,.61-.95,.87-1.57,.98-3.19,1.87-4.74,2.87-6.2,3.95-11.97,8.48-17.38,13.45-2.49,2.29-4.9,4.67-7.34,7.02-.49,.47-.97,.76-1.59,.26-.53-.43-.54-1.01,.09-1.65,1.84-1.86,3.69-3.71,5.6-5.49,4.24-3.95,8.61-7.76,13.36-11.09,3.61-2.53,7.36-4.84,11.07-7.22,.93-.6,1.88-.2,1.87,.97Z' />
					<path d='M36.17,193.99c-4.46-4.5-8.28-9.48-11.4-14.99-1.88-3.32-3.47-6.8-4.72-10.41-.34-.99-.65-2-1-2.99-.45-1.31,1.52-1.94,1.99-.45,1.81,5.82,4.37,11.29,7.69,16.39,2.64,4.05,5.68,7.8,9.14,11.19,.66,.65,1.33,1.3,2,1.94,.45,.43,.53,.96,.16,1.41-.4,.51-.99,.47-1.5,.12l-2.36-2.23Z' />
					<path d='M32.09,197.68c-3.96-3.99-7.45-8.36-10.44-13.13-2.31-3.69-4.29-7.55-5.87-11.6-.57-1.45-.98-2.95-1.58-4.39-.86-2.08,1.08-2.58,1.95-.49,1.31,3.14,2.47,6.34,3.93,9.41,2.66,5.58,6.15,10.65,10.24,15.29,1.13,1.28,2.35,2.47,3.51,3.73,.62,.68-1.01,1.93-1.74,1.19Z' />
					<path d='M88.38,58.73c4.06,.25,7.94,.63,11.69,1.86,3.93,1.29,7.69,2.91,11.19,5.14,.51,.32,.98,.72,1.43,1.12,.42,.38,.52,.89,.16,1.33-.38,.47-.85,.6-1.46,.22-1.9-1.17-3.78-2.4-5.77-3.39-4.94-2.45-10.15-3.94-15.7-4.08-2.75-.07-5.48,.04-8.19,.59-.6,.12-1.06-.11-1.21-.71-.17-.67,.13-1.17,.82-1.29,1.23-.21,2.47-.36,3.71-.5,1.16-.13,2.32-.2,3.33-.29Z' />
					<path d='M106.59,137.08c.11,.19,.23,.45,.38,.8,2.69,6,6.11,11.57,10.26,16.65,2.07,2.53,4.41,4.84,6.63,7.25,.19,.2,.42,.39,.54,.62,.18,.35,.19,.59-.27,.92-.97,.7-1.46,.18-2.11-.46-4.21-4.14-8.02-8.61-11.14-13.62-1.94-3.11-3.59-6.39-5.36-9.6-.29-.53-.6-1.06-.79-1.63-.86-1.91,1.11-2.25,1.85-.94Z' />
					<path d='M121.3,169.78c-.93-.87-1.88-1.73-2.8-2.62-4.84-4.69-9.11-9.85-12.72-15.54-1.36-2.14-2.52-4.4-3.77-6.6-.35-.61-.23-1.15,.37-1.47,.63-.34,1.12-.01,1.45,.57,.74,1.32,1.45,2.66,2.22,3.97,3.06,5.2,6.61,10.05,10.8,14.4,1.94,2.02,4.03,3.91,6.02,5.89,.81,.81-.54,2.36-1.56,1.4Z' />
					<path d='M122.25,203.89c.02,.86-.57,1.28-1.39,1.07-.88-.23-1.75-.51-2.63-.75-3.44-.97-6.96-1.32-10.52-1.48-4.65-.21-9.26,.12-13.88,.6-.94,.1-1.87,.28-2.8,.42-.93,.14-1.46-.15-1.53-.82-.07-.72,.32-1.04,1.26-1.2,4.39-.74,8.82-1.1,13.26-1.04,3.74,.05,7.49,.32,11.2,.74,1.99,.22,3.92,.94,5.87,1.48,.55,.15,1.15,.34,1.15,.98Z' />
					<path d='M101.58,189.54c3.63,.14,7.27,.15,10.88,.46,2.84,.25,5.69,.68,8.44,1.39,2.85,.74,5.6,1.89,8.38,2.89,.58,.21,.89,.71,.59,1.34-.3,.61-.8,.76-1.46,.5-1.65-.65-3.29-1.3-4.96-1.88-3.53-1.21-7.18-1.95-10.89-2.18-3.93-.24-7.87-.23-11.8-.36-.41-.01-.98-.21-1.19-.52-.43-.66,.04-1.38,.84-1.45,.39-.03,.78,0,1.17,0v-.2Z' />
					<path d='M35.3,200.74c-.44-.41-.88-.78-1.28-1.17-1.06-1.04,.8-2.05,1.67-1.23,3.53,3.29,7.33,6.21,11.49,8.65,3.39,1.99,6.94,3.67,10.69,4.89,.26,.09,.53,.18,.78,.28,.57,.24,.99,.64,.75,1.27-.23,.61-.75,.88-1.43,.63-1.33-.48-2.67-.92-3.98-1.44-3.89-1.54-7.58-3.49-11.01-5.88-2.26-1.58-4.4-3.31-6.57-5.02-.79-.62-.02,.02-1.12-.99Z' />
					<path d='M57.68,121.1c1.59-2.98,3.6-5.6,6.37-7.6,2.74-1.97,5.8-2.93,9.15-3.08,.69-.03,1.19-.01,2.07,.04,.64,.04,1.23,.13,1.75,.17,2,.17,2.06,2.37-.19,2.13-1.85-.2-3.68-.41-5.55-.1-3.2,.53-5.86,2.05-8.12,4.31-1.73,1.73-3.05,3.76-4.08,5.97-.48,1.04-1,1.25-1.52,1.01-1.03-.49-.47-1.75,.12-2.85Z' />
					<path d='M104.24,213.81c-4.01-.12-7.99,.26-11.94,.93-1.69,.29-3.38,.61-5.07,.94-.8,.15-1.41-.12-1.52-.75-.12-.76,.28-1.11,.99-1.26,4.81-1.03,9.67-1.75,14.6-1.88,2.5-.07,5,.04,7.5,0,.81-.01,1.34,.61,1.32,1.08-.03,.62-.54,.96-1.38,.95-1.5-.01-3,0-4.5,0Z' />
					<path d='M125.8,126.7c.17,.19,.57,.49,.78,.88,1.51,2.78,2.91,5.64,4.5,8.37,1.69,2.89,3.82,5.47,6.13,7.89,.21,.22,.4,.46,.61,.68,.43,.48,.51,1.02,.05,1.48-.46,.46-1.02,.44-1.46-.08-1.94-2.29-4.04-4.48-5.76-6.93-1.82-2.61-3.29-5.47-4.88-8.25-.44-.76-.76-1.59-1.1-2.41-.38-.93,0-1.65,1.12-1.64Z' />
					<path d='M75.61,216.8c-3.88,.12-7.69-.48-11.5-1.08-.54-.08-1.08-.25-1.61-.41-.56-.17-.94-.54-.81-1.16,.14-.62,.59-.93,1.24-.82,1.01,.17,2.01,.4,3.02,.58,4.72,.85,9.46,1.16,14.24,.65,.3-.03,.61-.06,.91-.03,.5,.06,.88,.28,.93,.87,.04,.55-.15,.97-.71,1.03-1.46,.15-2.92,.25-4.39,.35-.44,.03-.89,0-1.33,0Z' />
					<path d='M85.97,91.16c4.21,.4,7.89,2.09,11.08,4.79,1.17,.99,2.15,2.2,3.19,3.33,.45,.48,.45,1.02-.1,1.45-.56,.44-1.12,.31-1.53-.23-2.02-2.65-4.59-4.6-7.62-5.95-1.68-.75-3.43-1.26-5.28-1.37-.37-.02-.81-.11-1.06-.34-.24-.22-.44-.73-.34-.99,.12-.3,.57-.51,.91-.67,.21-.1,.49-.02,.74-.02Z' />
					<path d='M125.55,170.73c2.97,2.1,5.91,4.25,8.92,6.3,1.27,.87,2.68,1.53,4.04,2.27,.57,.32,.93,.73,.65,1.41-.24,.6-.86,.78-1.55,.43-4.83-2.44-9.33-5.39-13.42-8.9-.75-.64,.5-2.14,1.37-1.53Z' />
					<path d='M127.47,165.21c.59,.33,1.12,.8,1.65,1.23,3.67,3.04,7.61,5.67,11.84,7.86,.24,.13,.58,.27,.65,.48,.13,.35,.25,.89,.07,1.13-.2,.27-.79,.49-1.1,.39-.75-.24-1.45-.65-2.15-1.03-3.72-2.03-7.18-4.44-10.47-7.1-3.16-2.13-1.53-3.54-.48-2.96Z' />
					<path d='M104.88,206.1c3.72,.13,7.42,.28,11.06,1.13,.67,.16,1.15,.43,1.09,1.18-.06,.65-.66,1.01-1.46,.81-4.14-.99-8.34-1.02-12.56-1-.33,0-.76-.07-.97-.28-.23-.23-.43-.7-.35-.99,.08-.29,.48-.62,.79-.68,.79-.14,1.6-.14,2.4-.19Z' />
					<path d='M55.46,219.22c-.26-.07-.43-.09-.58-.16-2.42-1.08-4.85-2.12-7.25-3.25-1.45-.68-2.84-1.48-4.24-2.27-.78-.44-1-1.02-.69-1.54,.32-.55,.89-.63,1.69-.23,2.52,1.26,5.03,2.55,7.57,3.77,1.07,.51,2.2,.89,3.32,1.28,1.11,.39,1.41,1.08,.76,1.99-.14,.2-.41,.3-.58,.42Z' />
					<path d='M151.3,133.3c-.28,.39-.43,.85-.67,.9-.41,.09-1,.08-1.3-.16-1.78-1.43-3.56-2.88-5.23-4.43-1.12-1.04-2.09-2.26-3.1-3.43-.59-.68-.58-1.21-.05-1.65,.55-.45,.99-.33,1.63,.33,1.81,1.89,3.62,3.78,5.48,5.61,.7,.69,1.58,1.2,2.34,1.83,.32,.26,.55,.61,.89,1Z' />
					<path d='M139.1,159.95c2.35,1.39,4.68,2.81,7.03,4.19,.61,.36,.87,.8,.57,1.42-.32,.68-.95,.67-1.5,.36-2-1.11-3.98-2.25-5.95-3.41-.44-.26-.85-.55-1.3-.86-1.51-1.06,.08-2.52,1.14-1.71Z' />
					<path d='M136.99,165.31c2.17,1.27,4.33,2.56,6.52,3.81,.63,.36,.96,.83,.61,1.49-.35,.65-.97,.67-1.58,.31-2.2-1.28-4.41-2.53-6.56-3.87-1.31-.81-.47-2.62,1.02-1.74Z' />
					<path d='M33.51,203.85c.49,.41,.98,.83,1.49,1.23,1.38,1.09,2.77,2.19,4.18,3.25,.86,.65,1.06,1.1,.62,1.68-.43,.57-1.03,.59-1.8-.02-1.97-1.55-3.93-3.12-5.89-4.68-.87-.69,.73-2.02,1.41-1.45Z' />
					<path d='M104.32,165.28c-.24,.29-.42,.71-.71,.8-.33,.1-.89,.03-1.09-.2-.82-.89-1.55-1.85-2.28-2.81-.66-.88-1.29-1.79-1.91-2.7-.15-.22-.29-.49-.33-.75-.08-.48-.05-.94,.5-1.19,.54-.25,.92-.02,1.22,.38,.78,1.03,1.52,2.09,2.29,3.13,.61,.82,1.25,1.61,1.85,2.43,.17,.23,.27,.52,.46,.91Z' />
					<path d='M138.18,57.4c.4,.4,.81,.79,1.2,1.19,.47,.47,1,.72,1.64,.35,.42-.24,.68-.05,.89,.27,.84,1.26,1.69,2.51,2.49,3.8,.33,.53,.22,1.06-.32,1.45-.53,.38-1,.17-1.3-.26-1.57-2.24-3.1-4.51-4.64-6.77l.04-.04Z' />
					<path d='M148.63,153.57c.09,.79-.69,1.25-1.45,.85-1-.53-1.95-1.14-2.89-1.78-.84-.57-1.67-1.17-2.42-1.85-.24-.22-.33-.76-.26-1.1,.06-.25,.48-.66,.78-.59,1.7,.42,6.16,3.78,6.24,4.48Z' />
					<path d='M95.54,162.64c.12,.07,.43,.17,.61,.38,.47,.57,.98,1.14,1.29,1.79,.15,.31,0,.98-.26,1.2-.25,.21-.98,.22-1.2,.01-.57-.53-1.06-1.19-1.42-1.88-.4-.77,.05-1.48,.98-1.49Z' />
					<path d='M64.8,138.68c.3-2.44,.43-4.93,.96-7.32,.71-3.2,1.9-6.24,4.36-8.58,1.3-1.24,2.85-1.42,3.72-.29,.46,.6,.79,1.44,.82,2.19,.04,1.23-.16,2.48-.35,3.71-.21,1.4-.56,2.77-.78,4.17-.39,2.46-.73,4.93-1.09,7.4-.03,.22-.11,.43-.15,.65-.12,.67-.56,.84-1.16,.75-.65-.1-.81-.59-.73-1.11,.49-3.34,1.01-6.68,1.53-10.02,.22-1.37,.48-2.73,.71-4.1,.05-.33,.09-.68,.03-1-.08-.41-.26-.81-.4-1.21-.36,.22-.8,.36-1.07,.66-1.48,1.62-2.36,3.57-3.03,5.64-1.24,3.8-1.37,7.69-.92,11.61,.25,2.13,.61,4.26,1.77,6.16,1.29,2.11,3.21,1.42,4.03-.15,.59-1.14,1.06-2.37,1.39-3.61,.78-2.97,1.56-5.95,2.13-8.96,.44-2.3,.56-4.67,.81-7.01,.05-.48-.03-1.01,.15-1.43,.11-.27,.6-.55,.9-.53,.29,.02,.75,.39,.8,.65,.15,.95,.28,1.93,.19,2.87-.21,2.04-.48,4.08-.88,6.08-.49,2.38-1.13,4.73-1.73,7.09-.37,1.44-.71,2.9-1.2,4.31-.48,1.4-1.2,2.69-2.56,3.45-2.25,1.25-4.68,.41-5.93-1.96-1.49-2.82-1.84-5.89-2.05-8.99-.02-.36,0-.72,0-1.08l-.26-.03Z' />
					<path d='M5.28,85.08c-.14,0-.28-.03-.41-.09-.5-.23-.73-.82-.5-1.33C14.52,61.35,41.71,34.79,63.74,25.66c10.27-4.26,22.33-6,33.95-4.92,12.85,1.2,24.63,5.76,34.09,13.17,.43,.34,.51,.97,.17,1.4s-.97,.51-1.4,.17c-20.38-15.98-47.85-15.52-66.04-7.98C42.87,36.48,16.16,62.57,6.19,84.49c-.17,.37-.53,.59-.91,.59Z' />
					<path d='M150.09,149.62c-.18,0-.36-.05-.53-.15-7.51-4.65-12.63-9.71-16.61-16.38-4-6.71-7.21-15.66-10.4-29.02-.13-.54,.2-1.08,.74-1.21,.54-.13,1.08,.2,1.21,.74,6.01,25.17,12.11,35.49,26.12,44.17,.47,.29,.61,.91,.32,1.38-.19,.31-.52,.47-.85,.47Z' />
				</g>
			</motion.svg>
		</div>
	);
};

export default HeroBackground;
